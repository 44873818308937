html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

#navbar > .container {
    position: relative;
    justify-content: flex-start;
}

#navbar > .container > .navbar-toggler {
    margin-right: 1rem;
}

.bg-customBlue {
    background: rgb(14, 48, 86);
}

#responsive-navbar-nav {
    margin-right: 3rem;
}

#navMenu {
    flex-wrap: wrap;
    justify-content: center;
}

#navMenu > a, #navMenu > div > a, #helpMenu > a, #helpMenu > div > a, #helpMenu div > a > i {
    color: white !important;
}

#navMenu > a:hover, #navMenu > div > a:hover, #helpMenu:hover > a, #helpMenu:hover > div > a, #helpMenu:hover > div > a > i {
    color: lightgray !important;
}

#brand {
    display: flex;
    align-items: center;
    color: white;
    height: 50px;
    padding: 0;
    font-weight: 600;
    letter-spacing: .03rem;
    white-space: nowrap
}

#brand > svg {
    height: 100%;
    padding-right: .5rem;
    color: white;
}

#brand:hover, #brand:hover > svg {
    color: lightgray;
}

#helpMenu {
    position: absolute;
    right: 1rem;
    top: 0.4rem;
}

#helpMenu button {
    display: flex;
    align-items: center;
}

#helpMenu > div > a > i {
    font-size: 1.2rem;
}

#navMenu > div > div > a, #helpMenu > div > div > a {
    padding-top: .6rem;
    padding-bottom: .6rem;
}

#helpMenu .dropdown-menu {
    position: absolute;
}

.dropdown-header {
    font-weight: bold;
    color: lightslategray;
}

.system-message {
    color: white !important;
    background-color: red !important;
    font-weight: bold !important;
    text-align: center !important;
}

